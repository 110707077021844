
import store from '@/store';
import router from '@/router';
import jwtDefaultConfig from '../jwt/jwtDefaultConfig';

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => response,
      error => {
        // const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config


        if (status === 403) {
       if (response.status === 403) {
        store.dispatch('userlogout');
        router.push({ name: 'login'})
       };
       

      }
        // if (status === 401) {
        // if (response && response.status === 401) {
        //   if (!this.isAlreadyFetchingAccessToken) {
        //     this.isAlreadyFetchingAccessToken = true
        //     // this.refreshToken().then(r => {
        //     //   this.isAlreadyFetchingAccessToken = false

        //     //   // Update accessToken in localStorage
        //     //   this.setToken(r.data.accessToken)
        //     //   this.setRefreshToken(r.data.refreshToken)

        //     //   this.onAccessTokenFetched(r.data.accessToken)
        //     // })
        //   }
        //   const retryOriginalRequest = new Promise(resolve => {
        //     this.addSubscriber(accessToken => {
        //       // Make sure to assign accessToken according to your response.
        //       // Check: https://pixinvent.ticksy.com/ticket/2413870
        //       // Change Authorization header
        //       originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        //       resolve(this.axiosIns(originalRequest))
        //     })
        //   })
        //   return retryOriginalRequest
        // }
        return Promise.reject(error)
      },
    )
  }


  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  logout(...args) {
    return this.axiosIns.post(this.jwtConfig.logoutEndpoint, ...args)
  }
  sendverifycode(...args) {
    return this.axiosIns.get(this.jwtConfig.verifyemailEndpoint, ...args)
  }


  verifycode(...args) {
    return this.axiosIns.post(this.jwtConfig.verifyemailEndpoint, ...args)
  }

  me(...args) {
    return this.axiosIns.get(this.jwtConfig.refreshEndpoint, ...args)
  }

  getairtime(...args) {
    return this.axiosIns.get(this.jwtConfig.airtimeEndpoint, ...args)
  }

  getelectricity(...args) {
    return this.axiosIns.get(this.jwtConfig.electricityEndpoint, ...args)
  }

  getcabletvs(...args) {
    return this.axiosIns.get(this.jwtConfig.cabletvEndpoint, ...args)
  }
  validatecabletvs(...args) {
    return this.axiosIns.post(this.jwtConfig.validatecabletvEndpoint, ...args)
  }
  validateelectricity(...args) {
    return this.axiosIns.post(this.jwtConfig.validateelectricityEndpoint, ...args)
  }
  processelectricity(...args) {
    return this.axiosIns.post(this.jwtConfig.processelectricityEndpoint, ...args)
  }

  processvabletvs(...args)   {
    return this.axiosIns.post(this.jwtConfig.processcabletvEndpoint, ...args)
  }

  getdata(...args) {
    return this.axiosIns.get(this.jwtConfig.dataEndpoint, ...args)
  }

  processdata(...args) {
    return this.axiosIns.post(this.jwtConfig.dataEndpoint, ...args)
  }


  convertwallet(...args) {
    return this.axiosIns.post(this.jwtConfig.convertearningEndpoint, ...args)
  }

  processairtime(...args) {
    return this.axiosIns.post(this.jwtConfig.airtimeEndpoint, ...args)
  }


  getsingletransaction(hash, ...args) {
    return this.axiosIns.get(this.jwtConfig.transactionEndpoint +'/'+ hash, ...args)
  }
  resendpin(hash, ...args) {
    return this.axiosIns.get(this.jwtConfig.resendeducationEndpoint +'/'+ hash, ...args)
  }


  paytransaction(hash, ...args) {
    return this.axiosIns.get(this.jwtConfig.transactionpayEndpoint +'/'+ hash, ...args)
  }


  jambconfirmname(...args) {
    return this.axiosIns.post(this.jwtConfig.jambconfirmEndpoint, ...args)
  }

  processeducation(...args) {
    return this.axiosIns.post(this.jwtConfig.processeducationEndpoint, ...args)
  }

  processshowmax(...args) {
    return this.axiosIns.post(this.jwtConfig.processshowmaxEndpoint, ...args)
  }
  gettransactions(...args) {
    return this.axiosIns.get(this.jwtConfig.transactionsEndpoint, ...args)
  }


  getshowmaxplan(...args) {
    return this.axiosIns.get(this.jwtConfig.showmaxplangetEndpoint, ...args)
  }
  getmonnifylink(...args) {
    return this.axiosIns.get(this.jwtConfig.getmonnifylink, ...args)
  }
  getshowmax(...args) {
    return this.axiosIns.get(this.jwtConfig.showmaxgetEndpoint, ...args)
  }


  getrefearnplan(...args) {
    return this.axiosIns.get(this.jwtConfig.getreferralearningEndpoint, ...args)
  }
  geteducation(...args) {
    return this.axiosIns.get(this.jwtConfig.educationEndpoint, ...args)
  }



  geteducationprice(...args) {
    return this.axiosIns.post(this.jwtConfig.educationverifyEndpoint, ...args)
  }


  getreferrals(...args) {
    return this.axiosIns.get(this.jwtConfig.getreferralEndpoint, ...args)
  }


  verifydeposit(...args) {
    return this.axiosIns.post(this.jwtConfig.verifypaymentEndpoint, ...args)
  }

  getdeposits(...args) {
    return this.axiosIns.get(this.jwtConfig.depositsEndpoint, ...args)
  }

  getbankaccount(...args) {
    return this.axiosIns.get(this.jwtConfig.getbankEndpoint, ...args)
  }


  generatebankaccount(...args) {
    return this.axiosIns.post(this.jwtConfig.addbvnEndpoint, ...args)
  }


  // refreshToken() {
  //   return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
  //     refreshToken: this.getRefreshToken(),
  //   })
  // }
}
