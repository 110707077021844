<template>
<b-row class="mx-auto">

<b-col md="4" class="mx-auto">
      <b-card-code >
    
    <div class="demo-inline-spacing mx-auto">
    
  
    
       <b-spinner
        style="width: 3rem; height: 3rem;"
        class="mr-1 mx-auto"
        label="Large Spinner"
      />

    </div>

  
  </b-card-code>
</b-col>
</b-row>


</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BSpinner, BCardText, BRow, BCol, } from 'bootstrap-vue'


export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BSpinner,
    BCardText,
  },
  data() {
    return {

    }
  },

 
}
</script>
